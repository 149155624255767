<template>
  <div>
    <div v-if="products.length>0" class="pc related-product-container">
      <div class="h6 main" style="margin-bottom:16px">관련상품</div>
      <div class="position-relative">
        <div class="unselect btn-arrow left" v-if="v.left" @click="$refs.slick.prev()">
          <img src="/static/icon/fi_arrow-left.svg" alt="arrow left">
        </div>
        <slick ref="slick" :options="slickOptions" style="width:1120px" @afterChange="onChange">
          <div v-for="(card,idx) in products" :key="`card-${idx}`" class="card" @click="clickCard(card)">
            <img class="img-product" :src="card.img" :alt="card.name">
            <div class="flex-align" style="gap:8px">
              <img class="img-partner" style="padding:0" :src="card.partner.img">
              <div style="flex:1">
                <div class="subtitle7">{{ card.name }}</div>
                <div class="body4 main"><span class="body4-bold">{{ card.price.price|currencyNum }}</span>원 ~</div>
              </div>
            </div>
          </div>
        </slick>
        <div class="unselect btn-arrow right" v-if="v.right" @click="$refs.slick.next()">
          <img src="/static/icon/fi_arrow-right.svg" alt="arrow right">
        </div>
      </div>
    </div>
    <div v-if="products.length>0" class="mobile related-product-container">
    <div class="h8 main" style="margin:0 0 16px 16px">관련상품</div>

    <div class="margin-top-12">
      <div class="grid-card drag-scroll-mobile" :style="`grid-template-columns:repeat(${products.length}, 1fr)`">
        <div v-for="(card,idx) in products" :key="`card-${idx}`" class="card" @click="clickCard(card)">
          <img class="img-product" :src="card.img" :alt="card.name">
          <div class="flex-align" style="gap:8px">
            <img class="img-partner" style="padding:0" :src="card.partner.img">
            <div style="flex:1">
              <div class="body6">{{ card.name }}</div>
              <div class="body6 main"><span class="body6-bold">{{ card.price.price|currencyNum }}</span>원 ~</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
  import Slick from 'vue-slick'

  export default {
    name: "BlockRelatedProduct",
    components: {
      Slick
    },
    props: {
      products: {
        type: Array
      },
      isTheme: {
        type: Boolean,
        default: false
      }
    },
    created() {
      if(this.products.length>4) {
        this.v.right = true;
      }
    },
    data() {
      return {
        slickOptions: {
          dots: false,
          arrows: false,
          autoplay: false,
          infinite: false,
          centerMode: false,
          slidesToShow: 4,
          autoplaySpeed: "",
          centerPadding: "",
          variableWidth: false,
          slidesToScroll: 1
        },
        v: {
          left: false,
          right: false
        }
      }
    },
    methods: {
      onChange() {
        let pos = this.$refs.slick.currentSlide();
        this.v.left = pos > 0;
        this.v.right = pos + 3 < this.detail.products.length - 1 && this.detail.products.length > 4;
      },
      clickCard(card) {
        if(this.isTheme) {
          this.toast('서비스를 개설하시면 상품을 추가할 수 있습니다.');
        } else {
          this.routerPush(`detail?id=${card.id}`);
        }
      }
    }
  }
</script>

<style lang="stylus" scoped>
  .pc
    .related-product-container
      padding 60px 0
    .btn-arrow
      border 1px solid $border-sub
      background-color white
      width 60px
      height 60px
      border-radius 100%
      box-shadow 0 1px 3px rgba(0, 0, 0, 0.1)
      display flex
      align-items center
      justify-content center
      position absolute
      top calc(50% - 60px)
      z-index 1
    .left
      left -30px
    .right
      right -30px

    .card
      width 262px
      .img-product
        width 262px
        height 262px
        border-radius 8px
        margin-bottom 10px
      .img-partner
        object-fit cover
        width 32px
        height 32px

  .mobile
    .related-product-container
      padding 24px 0 16px 0
    .drag-scroll-mobile
      white-space nowrap
      overflow-x scroll
      scroll-snap-type x mandatory
      -webkit-overflow-scrolling touch
      overflow-y hidden

    .drag-scroll-mobile::-webkit-scrollbar
      display none

    .grid-card
      display grid
      grid-column-gap 12px

    .card
      width 144px
    .card:first-child
      margin-left 12px
    .card:last-child
      margin-right 12px
    .img-product
      width 144px
      height 144px
      border-radius 8px
      margin-bottom 6px
    .img-partner
      object-fit cover
      width 18px
      height 18px

</style>
<style lang="stylus">
  .pc
    .related-product-container
      .slick-track
        float left
      .slick-slide
        img
          padding 0

</style>
